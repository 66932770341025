import { initCookie } from "./main/cookie";
import { initCountUp } from './main/countUp';
// import { initSelectStyled } from "./main/select";
import { initAdminMenu } from "./layout/adminMenu";

//import swiper from './main/swiper';

document.addEventListener("DOMContentLoaded", () => {
	// console.log('DOMContentLoaded');
	initCookie();
	initCountUp();
	// initSelectStyled();
	initAdminMenu();
});
